import React, { FunctionComponent, ReactElement, useState } from 'react'
import { Tab, Tabs } from '@wh-components/core/Tabs/Tabs'
import { DealerProfile, OrganisationService, VehicleMake } from '../types/DealerProfile'
import { Employees } from './Employees'
import { Box } from '@wh-components/core/Box/Box'
import { Heading } from '@wh-components/core/Heading/Heading'
import { OrganisationOpeningHours } from './OrganisationOpeningHours'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'

export const DealerTabs: FunctionComponent<{
    dealerProfile: DealerProfile
    companyDescription?: string | null
    companyPhoto?: string
    resultListContainer: ReactElement
    resultListLabel?: string
}> = ({ dealerProfile, resultListContainer, companyDescription, companyPhoto, resultListLabel = 'Fahrzeuge' }) => {
    const [selected, setSelected] = useState(0)

    return (
        <Tabs renderAllPanels={true} selected={selected} onSelect={setSelected}>
            {resultListContainer && (
                <Tab label={resultListLabel}>
                    <Box paddingTop={{ tablet: 'm' }}>{resultListContainer}</Box>
                </Tab>
            )}
            {dealerProfile.services && Object.keys(dealerProfile.services).length !== 0 && (
                <Tab label="Service">
                    <Services services={dealerProfile.services} vehicleMakes={dealerProfile.vehicleMakes} />
                </Tab>
            )}
            {dealerProfile.openingHours && Object.keys(dealerProfile.openingHours).length !== 0 && (
                <Tab label="Öffnungszeiten">
                    <OrganisationOpeningHours openingHours={dealerProfile.openingHours} />
                </Tab>
            )}
            {dealerProfile.employees.length > 0 && (
                <Tab label="Team">
                    <Employees employees={dealerProfile.employees} />
                </Tab>
            )}
            {companyDescription?.trim() && (
                <Tab label="Über uns">
                    <OrganisationProfile companyDescription={companyDescription} companyPhoto={companyPhoto} />
                </Tab>
            )}
        </Tabs>
    )
}

const OrganisationProfile: FunctionComponent<{ companyDescription: string; companyPhoto?: string }> = ({
    companyDescription,
    companyPhoto,
}) => {
    return (
        <Box display="flex" minHeight={300} padding="m" paddingLeft={{ tablet: 0 }} flexDirection={{ phone: 'column', tablet: 'row' }}>
            {companyPhoto && <ResponsiveImage src={companyPhoto} maxHeight={300} maxWidth={300} alignSelf="flex-start" borderRadius="m" />}
            {companyDescription && (
                <Box marginTop={{ phone: 'm', tablet: 0 }} paddingLeft={{ tablet: 'm' }}>
                    <Heading text="Über uns" level={3} color="palette.primary.main" />
                    <Box dangerouslySetInnerHTML={{ __html: companyDescription }} />
                </Box>
            )}
        </Box>
    )
}

const Services: FunctionComponent<{ services: Record<string, OrganisationService[]>; vehicleMakes: VehicleMake[] }> = ({
    services,
    vehicleMakes,
}) => (
    <Box minHeight={300} display="flex" paddingHorizontal="m" paddingBottom="0" flexWrap="wrap" paddingLeft={{ tablet: 0 }}>
        <Box display="flex" flexWrap="wrap">
            {vehicleMakes.map((vehicleMake) => (
                <ResponsiveImage
                    marginTop="m"
                    height={35}
                    key={vehicleMake.makeId}
                    src={vehicleMake.logoUrl}
                    alt={`${vehicleMake.name} logo`}
                    title={`Offizieller ${vehicleMake.name}-Händler`}
                    marginRight="m"
                />
            ))}
        </Box>
        <Box display="flex" flexWrap="wrap" marginTop="m">
            {Object.keys(services).map((orgService) => (
                <Box key={orgService} marginRight="xxxl" marginBottom="m">
                    <Heading level={3} text={orgService} color="palette.primary.main" marginBottom="s" />
                    <ul>
                        {services[orgService].map((service) => (
                            <li key={service.serviceId}>{service.serviceName}</li>
                        ))}
                    </ul>
                </Box>
            ))}
        </Box>
    </Box>
)
