import React, { FunctionComponent } from 'react'
import { OpeningHours } from '@bbx/common/types/ad-detail/OrganisationDetails'
import { Text } from '@wh-components/core/Text/Text'

export const OpeningHour: FunctionComponent<{ hourIndex: number; hours: OpeningHours }> = (props) => {
    return (
        <Text>
            {!!props.hourIndex && <Text color="palette.koala"> | </Text>}
            {props.hours.timeFrom}-{props.hours.timeTo}
        </Text>
    )
}
