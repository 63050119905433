import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { AddressLines } from '@bbx/common/types/ad-detail/AdvertAddressDetails'
import { ContextLinkList } from '@bbx/common/types/contextLinks'

dayjs.extend(isBetween)
dayjs.extend(customParseFormat)

export interface OrganisationDetails {
    id: number
    addressLines: AddressLines
    country: string | null
    countryId: number
    description: string
    openingHours: OrganisationOpeningHours
    orgEmail: string | null
    orgLogoUrl: string | null
    orgName: string | null
    orgPhone: string | null
    organisationDetailLinkList: ContextLinkList
    partnerId: string
    postCode: string | null
    uuid: string
}

export interface OrganisationOpeningHours {
    openingHoursByGroup: OpeningHoursForGroup[]
}

export interface OpeningHoursForGroup {
    openingHoursGroup: OpeningHoursGroup
    openingHoursForDays: OpeningHoursForDay[]
}

export type OpeningHoursGroup = 'SHOP' | 'GARAGE'

export interface OpeningHoursForDay {
    dayOfWeek: DayOfWeek
    openingHours: OpeningHours[]
}

export interface OpeningHours {
    timeFrom: string
    timeTo: string
}

export type DayOfWeek = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY'

export const DayOfWeekGerman = {
    MONDAY: 'MONTAG',
    TUESDAY: 'DIENSTAG',
    WEDNESDAY: 'MITTWOCH',
    THURSDAY: 'DONNERSTAG',
    FRIDAY: 'FREITAG',
    SATURDAY: 'SAMSTAG',
    SUNDAY: 'SONNTAG',
} as const

export const DayOfWeekNumber = {
    MONDAY: 1,
    TUESDAY: 2,
    WEDNESDAY: 3,
    THURSDAY: 4,
    FRIDAY: 5,
    SATURDAY: 6,
    SUNDAY: 7,
} as const

export const inverseDayOfWeekNumber: { [day: number]: DayOfWeek } = {
    1: 'MONDAY',
    2: 'TUESDAY',
    3: 'WEDNESDAY',
    4: 'THURSDAY',
    5: 'FRIDAY',
    6: 'SATURDAY',
    7: 'SUNDAY',
}

export const dealerIsOpen = (
    openingHours: OrganisationOpeningHours,
    group: OpeningHoursGroup = 'SHOP',
    currentTime: number = Date.now(),
): boolean => {
    const dateFromTime = (time: string) =>
        dayjs(currentTime)
            .hour(parseInt(time.split(':')[0], 10))
            .minute(parseInt(time.split(':')[1], 10))

    const isCurrentTimeInAtLeastOneSlot = (slot: OpeningHours) =>
        dayjs(currentTime).isBetween(dateFromTime(slot.timeFrom), dateFromTime(slot.timeTo), 'minute', '[]')

    const openingHoursByGroup = openingHours.openingHoursByGroup.find((open) => open.openingHoursGroup === group)

    if (openingHoursByGroup) {
        const currentDay = inverseDayOfWeekNumber[dayjs(currentTime).day()]
        const openingHoursCurrentDay = openingHoursByGroup.openingHoursForDays.find((day) => day.dayOfWeek === currentDay)

        if (openingHoursCurrentDay) {
            return openingHoursCurrentDay.openingHours.some((slot) => isCurrentTimeInAtLeastOneSlot(slot))
        }
    }
    return false
}
