import { filterXSS } from 'xss'

export const sanitizeAdDescription = (description: string, stripLinks: boolean = false) => {
    return filterXSS(description, {
        whiteList: {
            p: [],
            strong: [],
            ol: [],
            ul: [],
            li: [],
            br: [],
            b: [],
            i: [],
            span: [],
            u: [],
            em: [],
            // filterXSS automatically filters any values starting with javascript:
            // and due to not allowing target we do not need to care about rel=noopener
            ...(!stripLinks && { a: ['href'] }),
        },
        stripIgnoreTag: true,
    })
}
