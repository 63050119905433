import React, { FunctionComponent } from 'react'
import { Employee } from '../types/DealerProfile'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ResponsiveImage } from '@wh-components/core/ResponsiveImage/ResponsiveImage'
import { ServerRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { AspectRatioBox } from '@wh-components/core/AspectRatioBox/AspectRatioBox'

export const Employees: FunctionComponent<{ employees: Employee[] }> = ({ employees }) => (
    <Box minHeight={300} display="flex" flexWrap="wrap" paddingBottom="m" paddingHorizontal={{ phone: 'm', tablet: 0 }}>
        {employees.map((employee) => (
            <EmployeeDetails key={employee.id} employee={employee} />
        ))}
    </Box>
)

const EmployeeDetails: FunctionComponent<{ employee: Employee }> = ({ employee }) => (
    <Box display="flex" flexBasis={{ phone: '100%', tablet: '50%' }} height={140}>
        <Box border="owl" marginRight={{ tablet: 'm' }} marginTop="m" paddingLeft="m" display="flex" flexGrow="1" borderRadius="m" gap="m">
            {employee.imageUrl && (
                <AspectRatioBox width="140px" ratio={1}>
                    <ResponsiveImage src={employee.imageUrl} backgroundColor="palette.babyseal" objectFit="contain" />
                </AspectRatioBox>
            )}
            <Box paddingTop="m">
                <Text as="p" color="palette.primary.main" fontWeight="bold" fontSize="l">
                    {employee.title} {employee.name} {employee.surname}
                </Text>
                <Text as="p">{employee.jobDescription}</Text>
                {employee.mobilePhone && (
                    <Text as="p" marginTop="s">
                        <Text fontWeight="bold" marginRight="s">
                            Telefon:
                        </Text>
                        <ServerRoutingAnchorLink type="anchor" href={`tel:${employee.mobilePhone}`}>
                            {employee.mobilePhone}
                        </ServerRoutingAnchorLink>
                    </Text>
                )}
                {employee.mobilePhone2 && (
                    <Text as="p">
                        <Text fontWeight="bold" marginRight="s">
                            Telefon 2:
                        </Text>
                        {employee.mobilePhone2}
                    </Text>
                )}
            </Box>
        </Box>
    </Box>
)
