import React, { Fragment, FunctionComponent } from 'react'
import { OpeningHours } from '../types/DealerProfile'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { DayOfWeek, DayOfWeekGerman, DayOfWeekNumber } from '@bbx/common/types/ad-detail/OrganisationDetails'

import { Heading } from '@wh-components/core/Heading/Heading'
import { OpeningHour } from '@bbx/common/components/OpeningHour'
import dayjs from 'dayjs'

const organisationUnits: Record<string, string> = {
    SHOP: 'Verkauf',
    GARAGE: 'Service und Teiledienst',
}

export const OrganisationOpeningHours: FunctionComponent<{ openingHours: Record<string, Record<string, OpeningHours[]>> }> = ({
    openingHours,
}) => {
    return (
        <Box minHeight={300}>
            <Box
                display="flex"
                paddingTop="m"
                paddingHorizontal={{ phone: 'm', tablet: 0 }}
                flexDirection={{ phone: 'column', tablet: 'row' }}
            >
                {Object.keys(openingHours).map((unit) => (
                    <Box
                        key={unit}
                        border="owl"
                        padding="m"
                        borderRadius="m"
                        marginRight="m"
                        marginBottom="m"
                        width={{ phone: '100%', tablet: 260 }}
                    >
                        <Heading text={organisationUnits[unit]} level={3} color="palette.primary.main" marginBottom="s" />
                        <UnitOpeningHours unit={openingHours[unit]} />
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

const UnitOpeningHours: FunctionComponent<{ unit: Record<string, OpeningHours[]> }> = ({ unit }) => {
    const currentDay = dayjs(Date.now()).day()
    return (
        <Fragment>
            {Object.keys(unit).map((day) => {
                return (
                    <Box key={day} fontWeight={currentDay === DayOfWeekNumber[day as DayOfWeek] ? 'bold' : undefined}>
                        <Box display="inline-block" width={42} marginRight="s">
                            <Text>{DayOfWeekGerman[day as DayOfWeek].substr(0, 2)}</Text>
                        </Box>
                        {unit[day].map((hours, index) => (
                            <OpeningHour key={index} hourIndex={index} hours={hours} />
                        ))}
                    </Box>
                )
            })}
        </Fragment>
    )
}
